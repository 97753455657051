const CourseData = [
    {
        title:"",
        summary:""
    },
    {
        title:"",
        summary:""
    },
    {
        title:"",
        summary:""
    },
    {
        title:"",
        summary:""
    },
    {
        title:"",
        summary:""
    },
    {
        title:"",
        summary:""
    },
    {
        title:"",
        summary:""
    },
    {
        title:"",
        summary:""
    },
    {
        title:"",
        summary:""
    },
    {
        title:"",
        summary:""
    },
    {
        title:"",
        summary:""
    }
]

export default CourseData;