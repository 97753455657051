import React from "react";
import Logo from  "../AuthLogo"

const Courses = (props) => {
  return (
    <div className="bg-white text-center border-2 rounded-lg cursor-pointer my-5 p-3 hover:scale-105 border-gray-300 hover:border-hoverColor">
      <div><Logo/></div>
      <div className="text-3xl">{props.title}</div>
      <div className="text-xl">{props.summary}</div>
    </div>
  );
};

export default Courses;
